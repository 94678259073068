<template>
  <div id="app">
    <Header v-if="isHideHeader" />
    <router-view :key="key" />
    <RightIconList />
  </div>
</template>

<script>
import Header from "@/components/Header";
import RightIconList from "@/components/RightIconList";

export default {
  name: "App",
  components: {
    Header,
    RightIconList,
  },
  data() {
    return {};
  },
  computed: {
    isHideHeader() {
      return this.$route.name !== "ArtPaint" && this.$route.name !== 'architectureIndex' && this.$route.name !== 'productIndex' && this.$route.name !== 'solutionIndex' && this.$route.name !== 'aboutIndex' && this.$route.name !== 'caseIndex' && this.$route.name !=='caseDetailIndex';
    },
    key() {
      return this.$route.path;
    },
  },
  created() {},
};
</script>

<style lang="scss" scope>
#app {
  background: #f5f5f5;
  position: relative;
  height: 100%;
}
</style>
