import Vue from "vue";
import Router from "vue-router";
// import { getPageTitle } from "@/utils";

Vue.use(Router);

export const constantRoutes = [
  {
    path: "/",
    name: "Home",
    component: () => import("@/views/home"),
    meta: { title: "主页" },
  },
  {
    path: "/about",
    name: "About",
    redirect: "/about/introduce",
    component: () => import("@/views/blank"),
    meta: { title: "关于我们", subTitle: "关于我们" },
    children: [
      {
        path: "introduce",
        name: "Introduce",
        component: () => import("@/views/about/introduce"),
        meta: { title: "集团介绍" },
      },
      {
        path: "advisory",
        name: "Advisory",
        component: () => import("@/views/about/advisory"),
        meta: { title: "品牌资讯" },
      },
      {
        path: "advisory-detail",
        name: "AdvisoryDetail",
        component: () => import("@/views/about/advisory/detail"),
        meta: { title: "品牌资讯详情" },
        hidden: true,
      },
      {
        path: "activity",
        name: "Activity",
        component: () => import("@/views/about/activity"),
        meta: { title: "品牌活动" },
      },
      {
        path: "activity-detail",
        name: "ActivityDetail",
        component: () => import("@/views/about/activity/detail"),
        meta: { title: "品牌活动详情" },
        hidden: true,
      },
      {
        path: "https://wecruit.hotjob.cn/SU60ace1dbbef57c118964b4fa/pb/social.html",
        meta: { title: "加入我们" },
      },
      {
        path: "contact",
        name: "Contact",
        component: () => import("@/views/about/contact"),
        meta: { title: "联系我们" },
      },
      {
        path: "zsjm",
        name: "Zsjm",
        component: () => import("@/views/about/contact/zsjm"),
        meta: { title: "招商加盟" },
        hidden: true,
      },
      {
        path: "zsjm-result",
        name: "ZsjmResult",
        component: () => import("@/views/about/contact/zsjm-result"),
        meta: { title: "招商加盟结果" },
        hidden: true,
      },
      {
        path: "charitable",
        name: "Charitable",
        component: () => import("@/views/about/charitable"),
        meta: { title: "公益活动" },
      },
    ],
  },
  {
    path: "/furnish",
    name: "Furnish",
    redirect: "/furnish/decoration",
    component: () => import("@/views/blank"),
    meta: { title: "装修", subTitle: "装修领域" },
    children: [
      {
        path: "product",
        meta: { title: "产品中心" },
        component: () => import("@/views/blank"),
        redirect: "/furnish/product/list",
        children: [
          {
            path: "list",
            name: "ProductList",
            component: () => import("@/views/furnish/product/list"),
            meta: { title: "产品列表" },
          },
          {
            path: "detail",
            name: "ProductDetail",
            component: () => import("@/views/furnish/product/detail"),
            meta: { title: "产品详情" },
            hidden: true,
          },
          {
            path: "custom",
            name: "ProductCustomDetail",
            component: () => import("@/views/furnish/product/custom"),
            meta: { title: "产品详情自定义" },
            hidden: true,
          },
        ],
      },
      {
        path: "serve",
        meta: { title: "服务中心" },
        component: () => import("@/views/blank"),
        children: [
          {
            path: "oldroom",
            name: "Oldroom",
            component: () => import("@/views/furnish/oldroom/index"),
            meta: { title: "焕新家" },
          },
          {
            path: "oldroom-outlets",
            name: "OldroomOutlets",
            component: () => import("@/views/furnish/oldroom/outlets"),
            meta: { title: "焕新家站点" },
            hidden: true,
          },
          {
            path: "oldroom-case",
            name: "oldroomCase",
            component: () => import("@/views/furnish/oldroom/case"),
            meta: { title: "焕新家案例" },
            hidden: true,
          },
          {
            path: "authenticity",
            component: () => import("@/views/furnish/serve/authenticity"),
            meta: { title: "真伪查询" },
          },
          {
            path: "certified",
            component: () => import("@/views/furnish/serve/certified"),
            meta: { title: "认证查询" },
          },
        ],
      },
      {
        path: "color-inspiration",
        meta: { title: "色彩灵感" },
        component: () => import("@/views/blank"),
        redirect: "/furnish/color-inspiration/index",
        children: [
          {
            path: "index",
            name: "ColorInspiration",
            component: () => import("@/views/furnish/color-inspiration/index"),
            meta: { title: "色彩中心" },
          },
          {
            path: "detail",
            name: "ColorInspirationDetail",
            component: () => import("@/views/furnish/color-inspiration/detail"),
            meta: { title: "色彩灵感" },
          },
        ],
      },
      {
        path: "cooperation",
        meta: { title: "招商合作" },
        component: () => import("@/views/blank"),
        // redirect: '/furnish/cooperation/index',
        children: [
          // {
          //   path: 'index',
          //   name: 'Cooperation',
          //   component: () => import('@/views/furnish/cooperation/index'),
          //   meta: { title: '招商合作' }
          // },
          // {
          //   path: 'about',
          //   name: 'About',
          //   component: () => import('@/views/furnish/cooperation/about'),
          //   meta: { title: '关于嘉宝莉' }
          // }
        ],
      },
      {
        path: "decoration",
        name: "HomeDecoration",
        component: () => import("@/views/furnish/home/index"),
        meta: { title: "家装主页" },
        hidden: true,
      },
      {
        path: "store",
        name: "SpecialtyStore",
        component: () => import("@/views/furnish/specialty-store/index"),
        meta: { title: "专卖店" },
        hidden: true,
      },
      {
        path: "paint",
        name: "PaintQuantityCalc",
        component: () => import("@/views/furnish/paint-quantity-calc/index"),
        meta: { title: "漆量计算" },
        hidden: true,
      },
      {
        path: "beautiful",
        name: "BeautifulSeam",
        component: () => import("@/views/furnish/beautiful-seam/index"),
        meta: { title: "美缝选色" },
        hidden: true,
      },
      {
        path: "art-paint",
        name: "ArtPaint",
        component: () => import("@/views/furnish/art-paint/index"),
        meta: { title: "艺术涂料" },
        hidden: true,
      },
      {
        path: "art-paint-product",
        name: "ArtPaintProduct",
        component: () => import("@/views/furnish/art-paint/product"),
        meta: { title: "艺术涂料产品" },
        hidden: true,
      },
      {
        path: "art-paint-case",
        name: "ArtPaintCase",
        component: () => import("@/views/furnish/art-paint/case"),
        meta: { title: "艺术涂料案例" },
        hidden: true,
      },
      {
        path: "one-stop-painting",
        name: "OneStopainting",
        component: () => import("@/views/furnish/one-stop-painting/index"),
        meta: { title: "一站式" },
        hidden: true,
      },

      {
        path: "stone-art-paint",
        name: "StoneArtPaint",
        component: () => import("@/views/furnish/stone-art-paint/index"),
        meta: { title: "石艺漆" },
        hidden: true,
      },
      {
        path: "stone-art-paint-case",
        name: "StoneArtPaintCase",
        component: () => import("@/views/furnish/stone-art-paint/case"),
        meta: { title: "石艺漆案例" },
        hidden: true,
      },
      {
        path: "stone-art-paint-case-detail",
        name: "StoneArtPaintCaseDetail",
        component: () => import("@/views/furnish/stone-art-paint/case-detail"),
        meta: { title: "石艺漆案例详情" },
        hidden: true,
      },
      {
        path: "wangee",
        name: "WangeeIndex",
        component: () => import("@/views/furnish/wangee/index"),
        meta: { title: "万宜漆" },
      },
      {
        path: "wangee/product",
        name: "WangeeProduct",
        component: () => import("@/views/furnish/wangee/product"),
        meta: { title: "万宜漆-商品列表" },
        hidden: true,
      },
      {
        path: "wangee/detail",
        name: "WangeeDetail",
        component: () => import("@/views/furnish/wangee/detail"),
        meta: { title: "万宜漆-详情" },
        hidden: true,
      },
      {
        path: "wangee/custom",
        name: "WangeeCustomDetail",
        component: () => import("@/views/furnish/wangee/custom"),
        meta: { title: "万宜漆-自定义详情" },
        hidden: true,
      },

      // {
      //   path: "wangee",
      //   meta: { title: "万宜漆" },
      //   component: () => import("@/views/blank"),
      //   redirect: "/furnish/wangee/index",
      //   children: [
      //     {
      //       path: "index",
      //       name: "WangeeIndex",
      //       component: () => import("@/views/furnish/wangee/index"),
      //       meta: { title: "万宜漆" },
      //     },
      //     {
      //       path: "product",
      //       name: "WangeeProduct",
      //       component: () => import("@/views/furnish/wangee/product"),
      //       meta: { title: "万宜漆-商品列表" },
      //     },
      //     {
      //       path: "detail",
      //       name: "WangeeDetail",
      //       component: () => import("@/views/furnish/wangee/detail"),
      //       meta: { title: "万宜漆-详情" },
      //     },
      //     {
      //       path: "custom",
      //       name: "WangeeCustomDetail",
      //       component: () => import("@/views/furnish/wangee/custom"),
      //       meta: { title: "万宜漆-自定义详情" },
      //       hidden: true,
      //     },
      //   ],
      // }
    ],
  },
  {
    path: "/building",
    name: "Building",
    meta: { title: "建筑", subTitle: "建筑领域" },
    component: () => import("@/views/blank"),
    children: [
      {
        path: "/architecture",
        name: "architectureIndex",
        component: () => import("@/views/architecture/index"),
        meta: { title: "建筑涂料" },
      },
      {
        path: "/solutionIndex",
        name: "solutionIndex",
        component: () => import("@/views/architecture/solution"),
        meta: { title: "解决方案" },
        hidden: true,
      },
      {
        path: "/aboutIndex",
        name: "aboutIndex",
        component: () => import("@/views/architecture/about"),
        meta: { title: "关于工程漆" },
        hidden: true,
      },
      {
        path: "/caseIndex",
        name: "caseIndex",
        component: () => import("@/views/architecture/case"),
        meta: { title: "工程案例" },
        hidden: true,
      },
      {
        path: "/caseDetailIndex",
        name: "caseDetailIndex",
        component: () => import("@/views/architecture/caseDetail"),
        meta: { title: "工程案例" },
        hidden: true,
      },
      {
        path: "/productIndex",
        name: "productIndex",
        component: () => import("@/views/architecture/product"),
        meta: { title: "产品中心" },
        hidden: true,
      },
      {
        // path: "http://fabrico.com.cn/",
        path: '/',
        meta: { title: "自然涂" },
      },
      {
        path: "http://www.jbl-xcl.com/",
        meta: { title: "地坪材料" },
      },
      {
        path: "http://www.sanval.com.cn/",
        meta: { title: "尚微" },
      },
    ],
  },
  {
    path: "/industry",
    name: "Industry",
    meta: { title: "工业", subTitle: "工业领域" },
    component: () => import("@/views/blank"),
    children: [
      {
        path: "https://wwwunit.carpoly.com/furniture",
        meta: { title: "家具涂料" },
      },
      {
        path: "industrial-coatings",
        name: "IndustrialCoatings",
        component: () => import("@/views/industry/industrial-coatings/index"),
        meta: { title: "工业涂料" },
      },
      {
        path: "industrial-coatings-product",
        name: "IndustrialCoatingsProductList",
        component: () =>
          import("@/views/industry/industrial-coatings/product-list"),
        meta: { title: "工业产品" },
        hidden: true,
      },
      {
        path: "industrial-coatings-product-detail",
        name: "IndustrialCoatingsProductDetail",
        component: () =>
          import("@/views/industry/industrial-coatings/product-detail"),
        meta: { title: "工业产品详情" },
        hidden: true,
      },
      {
        path: "industrial-coatings-case",
        name: "IndustrialCoatingsCaseList",
        component: () =>
          import("@/views/industry/industrial-coatings/case-list"),
        meta: { title: "工业产品案例" },
        hidden: true,
      },
      {
        path: "industrial-coatings-case-detail",
        name: "IndustrialCoatingsCaseDetail",
        component: () =>
          import("@/views/industry/industrial-coatings/case-detail"),
        meta: { title: "工业产品案例详情" },
        hidden: true,
      },
      {
        path: "ink",
        name: "Ink",
        component: () => import("@/views/industry/ink/index"),
        meta: { title: "油墨" },
      },
      {
        path: "ink-product",
        name: "InkProductList",
        component: () => import("@/views/industry/ink/product-list"),
        meta: { title: "油墨产品" },
        hidden: true,
      },
      {
        path: "ink-product-detail",
        name: "InkProductDetail",
        component: () => import("@/views/industry/ink/product-detail"),
        meta: { title: "油墨产品详情" },
        hidden: true,
      },
      {
        path: "ink-case",
        name: "InkCaseList",
        component: () => import("@/views/industry/ink/case-list"),
        meta: { title: "油墨产品案例" },
        hidden: true,
      },
      {
        path: "ink-case-detail",
        name: "InkCaseDetail",
        component: () => import("@/views/industry/ink/case-detail"),
        meta: { title: "油墨产品案例详情" },
        hidden: true,
      },
    ],
  },
  {
    path: "/international",
    name: "International",
    meta: { title: "国际EN", subTitle: "国际市场" },
    component: () => import("@/views/blank"),
    children: [
      {
        path: "https://en.carpoly.com/",
        meta: { title: "BUI" },
      },
      {
        path: "http://euroquimica.com/",
        meta: { title: "Euroquimica" },
      },
    ],
  },
  {
    path: "https://carpoly.tmall.com/",
    name: 'Buy',
    meta: { title: "购买" },
  },
  {
    path: "/search",
    name: "Search",
    component: () => import("@/views/search"),
    meta: { title: "查询页" },
    hidden: true,
  },
  {
    path: "/customer-service",
    name: "CustomerService",
    component: () => import("@/views/customer-service/index"),
    meta: { title: "客服中心" },
    hidden: true,
  },
  //
  {
    path: "/architecture/solution",
    name: "solutionIndex",
    component: () => import("@/views/search"),
    meta: { title: "查询页" },
    hidden: true,
  },
  // 404 page must be placed at the end !!!
  { path: "*", redirect: "/home", hidden: true },
];

const createRouter = () =>
  new Router({
    mode: "history", // require service support
    scrollBehavior: (to) => ({ top: 0 }),
    routes: constantRoutes,
  });

const router = createRouter();

router.beforeEach((to, from, next) => {
  // start progress bar
  // NProgress.start()
  // set page title
  // const wihtes = ["/furnish/product/custom", "/furnish/product/detail"];
  // if (!wihtes.includes(to.path)) {
  //   document.title = getPageTitle(to.meta.title);
  // }


  setTimeout(() => {
    var _hmt = _hmt || [];
    (function () {
      document.getElementById('baidu_tj') && document.getElementById('baidu_tj').remove();
      if (['/furnish/art-paint', '/furnish/art-paint-product', '/furnish/art-paint-case'].includes(to.path)) {
        var hm = document.createElement("script");
        hm.src = "https://hm.baidu.com/hm.js?358ed77d8bd21ed7b77c901d046ffc28";
        hm.id = "baidu_tj"
        var s = document.getElementsByTagName("script")[0];
        s.parentNode.insertBefore(hm, s);
      }
    })();
  }, 0);


  next();
});

const VueRouterPush = Router.prototype.push;
Router.prototype.push = function push(to) {
  return VueRouterPush.call(this, to).catch((err) => err);
};
// router.afterEach(() => {
//   // finish progress bar
//   // NProgress.done()
// })

export default router;
